import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import store from "@/store";

var firebaseConfig = {
  apiKey: "AIzaSyCQYxWN4UTvXiDHM5rQ7WesUJl1a0w80jg",
  authDomain: "school-house-apartments.firebaseapp.com",
  databaseURL: "https://school-house-apartments.firebaseio.com",
  projectId: "school-house-apartments",
  storageBucket: "school-house-apartments.appspot.com",
  messagingSenderId: "11455082821",
  appId: "1:11455082821:web:5b92a43a72e4ca67"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
var storage = firebase.storage();

auth.onAuthStateChanged(user => {
  if (user) {
    user.getIdTokenResult().then(idTokenResult => {
      user.admin = idTokenResult.claims.admin;
    });

    store.dispatch("updateUser", user);
  } else {
    store.dispatch("updateUser", null);
  }

  store.dispatch("changeAuthReady");
});

export { db, auth, functions, storage };
